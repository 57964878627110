import React from 'react';
import PropTypes from 'prop-types';

import { PageRoutes } from '../../data/constants';

import Layout from '../../components/Layout';

import ActivitiesTemplate from '../../templates/Activities';

const Activities = ({ pageContext, data }) => {
  const { language } = pageContext;
  const {
    markdownRemark: { frontmatter }
  } = data;
  const { pageTitle, ...pageContent } = frontmatter;

  return (
    <Layout
      pageTitle={pageTitle}
      activePage={PageRoutes.ACTIVITIES}
      language={language}
    >
      <ActivitiesTemplate {...{ ...pageContent, language }} />
    </Layout>
  );
};

Activities.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        industrial: PropTypes.object.isRequired,
        professional: PropTypes.object.isRequired,
        header: PropTypes.object.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Activities;
