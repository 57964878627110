import React from 'react';
import PropTypes from 'prop-types';

import HeaderSection from '../../components/HeaderSection';
import IndustrialCompetence from '../../components/IndustrialCompetence';
import ProfessionalCompetence from '../../components/ProfessionalCompetence';

const ActivitiesTemplate = ({ header, industrial, professional }) => (
  <>
    <HeaderSection {...header} />
    <ProfessionalCompetence {...professional} />
    <div className="px-4 container mx-auto my-8">
      <div className="border border-black-100" />
    </div>
    <IndustrialCompetence {...industrial} />
  </>
);

ActivitiesTemplate.propTypes = {
  industrial: PropTypes.object.isRequired,
  professional: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired
};

export default ActivitiesTemplate;
