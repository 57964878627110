import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleWrapper from '../SectionTitleWrapper';
import IndustrialCompetenceCard from '../IndustrialCompetenceCard';

const IndustrialCompetence = ({
  title,
  subtitle,
  renewableEnergy,
  energyStorage,
  mobility,
  machines,
  networks,
  control
}) => (
  <SectionTitleWrapper title={title} subtitle={subtitle}>
    <div className="container mx-auto px-4 flex justify-center">
      <div className="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <IndustrialCompetenceCard
          {...{
            ...renewableEnergy
          }}
        />
        <IndustrialCompetenceCard
          {...{
            ...energyStorage
          }}
        />
        <IndustrialCompetenceCard
          {...{
            ...mobility
          }}
        />
        <IndustrialCompetenceCard
          {...{
            ...machines
          }}
        />
        <IndustrialCompetenceCard
          {...{
            ...control
          }}
        />
        <IndustrialCompetenceCard
          {...{
            ...networks
          }}
        />
      </div>
    </div>
  </SectionTitleWrapper>
);

IndustrialCompetence.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  renewableEnergy: PropTypes.object.isRequired,
  energyStorage: PropTypes.object.isRequired,
  mobility: PropTypes.object.isRequired,
  machines: PropTypes.object.isRequired,
  networks: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired
};

export default IndustrialCompetence;
