import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const IndustrialCompetenceCard = ({ title, points = [], backgroundImage }) => (
  <div className="p-4 flex flex-col space-y-4 bg-white-100 shadow-100 flex-1 max-w-md sm:space-y-8 flex-grow w-full pb-8">
    <div style={{ height: '200px' }}>
      <Image
        image={backgroundImage}
        alt={title}
        className="object-cover object-top w-full h-full"
      />
    </div>
    <div className="flex-1 flex flex-col space-y-4 h-full">
      <h3 className="card-title-sm text-primary-300 sm:card-title">{title}</h3>
      <ul className="list-disc list-outside space-y-2 px-6">
        {points.split('//').map((point, index) => (
          <li key={index} className="sm:card-text card-text-sm text-black-100">
            {point}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

IndustrialCompetenceCard.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default IndustrialCompetenceCard;
