import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const ProfessionalCompetenceCard = ({
  title,
  points = [],
  backgroundImage
}) => (
  <div className="flex flex-col bg-white-100 shadow-100 flex-1 flex-grow w-full max-w-md lg:max-w-full relative">
    <div className="absolute w-full h-full top-0 left-0">
      <Image
        image={backgroundImage}
        alt={title}
        className="object-cover h-full"
      />
    </div>
    <div className="flex-1 flex flex-col space-y-4 h-full relative p-8 w-full">
      <h3 className="card-title-sm text-primary-200 sm:card-title">{title}</h3>
      <ul className="list-disc list-outside space-y-2 px-4">
        {points.split('//').map((point, index) => (
          <li key={index} className="sm:card-text card-text-sm text-white-200">
            {point}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

ProfessionalCompetenceCard.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.string.isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  ]).isRequired
};

export default ProfessionalCompetenceCard;
