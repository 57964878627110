import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleWrapper from '../SectionTitleWrapper';
import ProfessionalCompetenceCard from '../ProfessionalCompetenceCard';

const ProfessionalCompetence = ({
  title,
  subtitle,
  analyze,
  strategy,
  technology,
  management,
  team,
  process
}) => (
  <SectionTitleWrapper title={title} subtitle={subtitle}>
    <div className="container mx-auto px-4 flex justify-center">
      <div className="grid gap-4 md:gap-8 grid-cols-1 lg:grid-cols-2">
        <ProfessionalCompetenceCard {...analyze} />
        <ProfessionalCompetenceCard {...strategy} />
        <ProfessionalCompetenceCard {...management} />
        <ProfessionalCompetenceCard {...technology} />
        <ProfessionalCompetenceCard {...team} />
        <ProfessionalCompetenceCard {...process} />
      </div>
    </div>
  </SectionTitleWrapper>
);

ProfessionalCompetence.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  strategy: PropTypes.object.isRequired,
  analyze: PropTypes.object.isRequired,
  management: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  technology: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired
};

export default ProfessionalCompetence;
