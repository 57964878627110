import { graphql } from 'gatsby';

import Activities from './template';

export const pageQuery = graphql`
  query ActivitiesTemplate($language: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "Activities" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        pageTitle
        header {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        professional {
          title
          subtitle
          analyze {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          strategy {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          technology {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          management {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          team {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          process {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        industrial {
          title
          subtitle
          renewableEnergy {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          energyStorage {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          mobility {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          machines {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          control {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          networks {
            title
            points
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Activities;
